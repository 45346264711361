import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { DeButtonModule, DeDatepickerModule, DeDrawerModule, DeFooterModule, DeHeaderModule, DeListModule, DeLoaderModule } from '@de-electron/electron-angular-components';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './config/msal.config';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { AppConfig } from './config/app.config';
import { GlobalLoaderComponent } from './shared/components/global-loader/global-loader.component';
import { GlobalLoaderInterceptor } from './services/interceptor/global-loader.interceptor';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { SharedModule } from './shared/shared.module';



export const deModules = [
  DeFooterModule,
  DeHeaderModule,
  DeButtonModule,
  DeListModule,
  DeLoaderModule,
  DeDatepickerModule,
  DeDrawerModule,
]

@NgModule({
  declarations: [
    AppComponent,
    UnauthorizedComponent,
    HeaderComponent,
    FooterComponent,
    GlobalLoaderComponent,

  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    MsalModule.forRoot(
      MSALInstanceFactory(),
      MSALGuardConfigFactory(),
      MSALInterceptorConfigFactory()
    ),

  ],
  exports: [RouterModule],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: getUseFactory,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalLoaderInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {}
export function getUseFactory(config: AppConfig) {
  return () => config.load();
}
